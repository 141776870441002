exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-best-surf-camps-france-js": () => import("./../../../src/pages/locations/best-surf-camps-france.js" /* webpackChunkName: "component---src-pages-locations-best-surf-camps-france-js" */),
  "component---src-pages-locations-best-surf-camps-portugal-js": () => import("./../../../src/pages/locations/best-surf-camps-portugal.js" /* webpackChunkName: "component---src-pages-locations-best-surf-camps-portugal-js" */),
  "component---src-pages-locations-best-surf-camps-spain-js": () => import("./../../../src/pages/locations/best-surf-camps-spain.js" /* webpackChunkName: "component---src-pages-locations-best-surf-camps-spain-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

